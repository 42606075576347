import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  language: string;

  constructor() { }

  ngOnInit() {

    $('.navbar-nav .nav-link').click(function () {
      $('.navbar-nav .nav-link').removeClass('active');
      $(this).addClass('active');
    })

    $('.navbar-nav .nav-link').click(function () {
      $('.navbar-nav .nav-link').removeClass('active');
      $(this).addClass('active');
    })

    $('.navbar-nav>li>a').on('click', function () {
      $('.navbar-collapse').collapse('hide');
    });

    $('.navbar-nav>li>div>a').on('click', function () {
      $('.navbar-collapse').collapse('hide');
    });

    this.language = (sessionStorage.getItem('languageSession'));

    /**
 * Listen to scroll to change navbar opacity class
 */
    function checkScroll() {
      var startY = $('.navbar').height() * 5.5; //The point where the menubar changes in px

      if ($(window).scrollTop() > startY) {
        $('.navbar').addClass("scrolled");
      } else {
        $('.navbar').removeClass("scrolled");
      }
    }
  }

  english() {

    sessionStorage.setItem('languageSession', 'English');
    location.reload();

  }

  tamil() {
    sessionStorage.setItem('languageSession', 'Tamil');
    location.reload();

  }

  hindi() {

    sessionStorage.setItem('languageSession', 'Hindi');
    location.reload();

  }

}
