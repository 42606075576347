import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  language:string;

  constructor() { }

  ngOnInit() {

    this.language = (sessionStorage.getItem('languageSession'));
    // alert(this.language);

  }

  english(){

    sessionStorage.setItem('languageSession', 'English');
    location.reload();

  }

  tamil(){
    sessionStorage.setItem('languageSession', 'Tamil');
    location.reload();

  }

  hindi(){

    sessionStorage.setItem('languageSession', 'Hindi');
    location.reload();

  }

}