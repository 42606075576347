import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'DharmapuramAdhinamWebsite';

  language:string;

  ngOnInit() {

    this.language = (sessionStorage.getItem('languageSession'));

    if( this.language == null || this.language == undefined || this.language == '' ){
      this.language = 'English';
      sessionStorage.setItem('languageSession', (this.language));
    } 


  }

  pageLoaderFlag: boolean;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.pageLoaderFlag = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.pageLoaderFlag = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }
  onActivate() {
    window.scroll(0,0); 
  }
}
